
export enum PaymentRequirement{
  NEED_TO_PAY = 'NEED_TO_PAY',
  NO_NEED_P0 = 'NO_NEED_P0',
  NO_NEED_OTHER = 'NO_NEED_OTHER',
  NO_NEED_UNDER_18 = 'NO_NEED_UNDER_18',
  NO_NEED_REGISTERED_BEFORE_APR_15_2024 = 'NO_NEED_REGISTERED_BEFORE_APR_15_2024',
  NO_NEED_SCHOLARSHIP = 'NO_NEED_SCHOLARSHIP',
  NEED_TO_PAY_PARTIAL_SCHOLARSHIP = 'NEED_TO_PAY_PARTIAL_SCHOLARSHIP',
}

export const PaymentRequirementMap  = {
  NEED_TO_PAY: {
    title: 'needs to pay',
    enum: PaymentRequirement.NEED_TO_PAY
  },
  NO_NEED_P0: {
    title: 'no need to pay (P0)',
    enum: PaymentRequirement.NO_NEED_P0
  },
  NO_NEED_OTHER: {
    title: 'no need to pay (other)',
    enum: PaymentRequirement.NO_NEED_OTHER
  },
  NO_NEED_UNDER_18: {
    title: 'no need to pay (under 18)',
    enum: PaymentRequirement.NO_NEED_UNDER_18
  },
  NO_NEED_REGISTERED_BEFORE_APR_15_2024: {
    title: 'no need (registered before Apr.15, 2024)',
    enum: PaymentRequirement.NO_NEED_REGISTERED_BEFORE_APR_15_2024
  },
  NO_NEED_SCHOLARSHIP: {
    title: 'no need to pay (scholarship)',
    enum: PaymentRequirement.NO_NEED_SCHOLARSHIP
  },
  NEED_TO_PAY_PARTIAL_SCHOLARSHIP: {
    title: 'needs to pay (partial scholarship)',
    enum: PaymentRequirement.NEED_TO_PAY_PARTIAL_SCHOLARSHIP
  },
};
